@import '../variables';
@import "~breakpoint-sass";

$bk-desktop: 5000px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.Card {
  flex: 0 0 100%; // Каждая карточка занимает всю ширину экрана
  display: flex;
  flex-flow: column;
  gap: 16px;
  padding: 48px 24px;
  background-image: url("../../public/bgCard.png");
  background-size: cover;
  background-repeat: no-repeat;
  transition: width 0.5s ease; // Анимация изменения ширины карточек

  .Title {
    margin-bottom: 32px;
  }
}


.Wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  @include breakpoint($bk-desktop) {
    display: none;
  }
  @include breakpoint($bk-table) {
    display: none;
  }
  @include breakpoint($bk-table-landscape) {
    display: none;
  }
}

.Slider {
  display: flex;
  transition: transform 0.5s ease;
  width: 100%; // Устанавливаем ширину слайдера на 100% экрана
}

.Indicators {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 12px;
}

.Indicator {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.Active {
  background-color: #333;
}
