// variables.scss



$primary-color: #930E15;
$secondary-color: #6c757d;

$background-color: #060606;

$text-01: #E1E1E1;
$text-02: #5F5E5E;

