// Button.scss
@import '../variables';

.button {
  width: auto;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  color: $text-01;
  background-color: $primary-color;
  border: none;
  cursor: pointer;

  display: flex;
  min-width: 275px;
  padding: 21px 55px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 0.4s;
  text-align: center;

  /* Button title 1 */
  font-family: Jost, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
}

.button.outline {
  background-color: transparent;
  color: $primary-color;
  border: 2px solid $primary-color;
}

.button.ghost {
  background-color: transparent;
  color: $text-01;
  border: none;
  text-decoration: underline;
}

.button:hover {
  transition: 0.4s;
  opacity: 0.8;
  background-color: $primary-color;
  color: $text-01;
}

.button.ghost:hover {
  background-color: transparent;
  color: $primary-color;
  border: none;
  text-decoration: underline;
}
