@import '../../variables';
@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.color {
  text-align: left;
  color: $text-01;
  margin: 0;
  width: 100%;
}

.Wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  //padding: 44px;
  transition: max-height 0.3s ease-in-out; /* Анимация высоты элемента */
  overflow: hidden; /* Скрытие содержимого, когда элемент свернут */
}

.Text {
  text-align: left;
  color: $text-02;

  font-family: Jost, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  @include breakpoint($bk-phone) {
    text-align: left;
    font-size: 16px;
  }
}

hr {
  margin: 16px 0;
}

.Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

/* Белая кнопка */
.RedButton {
  background-image: url("./white.png");
}

.WhiteButton {
  background-image: url("./red.png");
}

button {
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-color: transparent;
  color: transparent;
  border: none;
}
