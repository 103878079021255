@import 'src/variables';
@import "~breakpoint-sass";

$bk-desktop: 5000px 1095px;
//$bk-table-landscape: 1440px 811px;
//$bk-table: 811px 611px;
$bk-phone: 1095px 0px;

.Wrapper {
  display: grid;
  gap: 116px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: $background-color;
  overflow: hidden;
  position: relative;

  @include breakpoint($bk-phone) {
    gap: 40px;
  }
}

.Block1 {
  .blood {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 12%;
    @include breakpoint($bk-phone) {
      left: 19%;
      top: -50px
    }
  }
  width: 100%;
  //noinspection CssInvalidPropertyValue
  height: 100svh;
  display: flex;
  flex-flow: column;
  align-content: center;
  justify-content: space-between;
  color: $text-01;
  gap: 16px;
  padding-bottom: 40px;

  .bg {
    position: absolute;
    z-index: -1;
    width: 714px;
    height: 714px;
    border-radius: 714px;
    background: radial-gradient(50% 50% at 50% 50%, #413A3A 0%, rgba(0, 0, 0, 0.00) 100%);
  }

  .Row3 {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 40px 24px 0;
  }

  .Row1 {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: end;
    width: 100%;
    gap: 16px;
    height: 100%;
    padding: 0 24px ;

    background-image: url("../../public/Milana.png");
    background-size: contain;
    background-position: center;
    background-position-y: bottom;
    background-repeat: no-repeat;

    @include breakpoint($bk-phone) {
      background-position-x: center;
      background-position-y: bottom;
      background-size: contain;
    }
  }

  .Row2 {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: auto;

    .Subtitle {
      color: $text-01;
      text-align: center;

      font-family: Jost, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;

      margin-bottom: 40px;

      @include breakpoint($bk-phone) {
        font-size: 16px;
      }
    }
    button {
      display: flex;
      width: 275px;
      padding: 21px 55px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}

.SvgMobile {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include breakpoint($bk-desktop) {
    display: none;
  }
}

.SvgDesktop {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include breakpoint($bk-phone) {
    display: none;
  }
}

.Block2 {
  width: 100%;
  height: auto;

  .TitleSubtitle {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    @include breakpoint($bk-phone) {
      gap: 12px;
    }

    .Title {
      @include breakpoint($bk-phone) {

      }
    }
  }

  .ImgContainer1 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 64px 124px 56px;

    .dsktopimg {
      width: 100%;
      @include breakpoint($bk-phone) {
        display: none;
      }
    }

    .mobile {
      display: none;
      @include breakpoint($bk-phone) {
        display: flex;
        width: 100%;
      }
    }

    @include breakpoint($bk-phone) {
      padding: 64px 0 24px;
    }
  }

  .WrapperForCards {
    width: 100%;
    height: auto;
    display: flex;
    gap: 20px;
    padding: 0 20px;
    @include breakpoint($bk-phone) {
      display: none;
    }

    .Card {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      gap: 16px;
      padding: 48px 24px;
      background-image: url("../../public/bgCard.png");
      background-size: cover;
      background-repeat: no-repeat;

      .Title {
        margin-bottom: 32px;
      }
    }
  }
}

h3 {
  /* Headline 3 */
  text-align: center;
  color: $text-01;
  font-family: Jost, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 36px */
  letter-spacing: -2.16px;
  text-transform: uppercase;
  @include breakpoint($bk-phone) {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
}

h6 {
  /* Headline 7 figma */
  color: $text-01;
  font-family: Jost, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
  text-transform: uppercase;
  @include breakpoint($bk-phone) {
    text-align: center;
    font-family: Jost, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
    text-transform: uppercase;
  }
}

.forSubtitle {
  padding: 0 48px;
}

.Desk1 {
  color: $text-01;

  font-family: Jost, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  @include breakpoint($bk-phone) {
    font-size: 16px;
  }
}

.Desk3 {
  color: $text-01;
  font-family: Jost, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  width: 100%;
}

.Desk4 {
  color: $text-02;
  //text-align: center;
  font-family: Jost, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  margin-bottom: 40px;

  @include breakpoint($bk-phone) {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 20px */
  }
}

.TitlesSvg {
  @include breakpoint($bk-phone) {
    svg {
      zoom: 0.7;
    }
  }
}

.WrapperPoints {
  width: 100%;
  display: grid;
  justify-content: start;
  gap: 16px;
}

.Point {
  display: flex;
  gap: 12px;
  text-align: left;

  .Desk1 {
    width: 100%;
  }
}

.Block3 {
  display: flex;
  flex-flow: column;
  align-items: center;

  .WrapperCards {
    display: flex;
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 20px;
    @include breakpoint($bk-phone) {
      flex-flow: column;
      padding: 0 45px;
    }

    .Card {
      width: 100%;
      display: flex;
      flex-flow: column;
      padding: 16px 32px 75px;
      border: 1px solid $primary-color;
      gap: 16px;

      @include breakpoint($bk-phone) {
        gap: 8px;
        padding-bottom: 42px;
      }

      .Desk4 {
        color: $text-01;
        text-align: left;
        margin: 0;
        font-size: 16px;
      }

      .RecRed {
        width: 100%;
        display: flex;
        justify-content: center;

        .Number {
          background-color: $primary-color;
          height: 90px;
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 56px;

          @include breakpoint($bk-phone) {
            width: 100%;
            padding: 6px 0px;
            height: auto;
            margin-bottom: 12px;
          }


          color: $text-01;
          text-align: center;

          font-family: Jost, sans-serif;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 48px */

          @include breakpoint($bk-phone) {

            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }

      @include breakpoint($bk-phone) {
        gap: 12px;
      }
    }
  }

  .RedBlock {
    width: 100%;
    background-color: $primary-color;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 52px;
    gap: 38px;
    margin-top: 20px;

    background-image: url('../../public/bgRedBlock.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .Text {
    color: $text-01;
    text-align: center;

    font-family: Jost, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 32px */
    max-width: 991px;

    @include breakpoint($bk-phone) {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .Text1 {
    color: $text-01;
    text-align: center;

    font-family: Jost, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 32px */
    max-width: 991px;
    
    @include breakpoint($bk-phone) {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
}

.Block4 {
  display: flex;
  flex-flow: column;
  align-items: center;

  .WrapperCards4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 80px 20px;
    width: 100%;
    padding: 40px 20px;
    margin: 40px 0;
    @include breakpoint($bk-phone) {
      display: flex;
      flex-flow: column;
      padding: 40px 45px;
      gap: 24px;
      margin: 0;
    }

    .Card4 {
      width: 100%;
      height: 100%;

      .NameCard {
        width: fit-content;
        padding: 6px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 24px;
        background-color: $primary-color;
        color: $text-01;


        /* Caption */
        font-family: Jost, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 20px */
      }

      .Content {
        height: 100%;
        padding: 56px 24px;
        gap: 36px;
        display: flex;
        flex-flow: column;
        align-items: center;
        background: var(--Gradient, linear-gradient(270deg, #0C0B0B 0%, #2E2727 210.56%));
      }
    }
  }
}

.Block5 {
  width: 100%;
  height: 90vh; // Временно
  display: flex;
  flex-flow: column;
  align-items: center;
  background-image: url("../../public/Mask.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include breakpoint($bk-phone) {
    background-image: url("../../public/MaskPhone.png");
  }

  .Content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 32px 40px;

    width: 100%;
    height: 100%;

    .Card {
      max-width: 330px;
      width: 100%;
      display: grid;
      gap: 50px;

      h6 {
        text-align: left;
      }

      @include breakpoint($bk-phone) {
        gap: 24px;
      }
    }

    @include breakpoint($bk-phone) {
      flex-flow: column;
    }
  }
}

.Block6 {
  display: flex;
  width: 100%;
  height: auto;
  //justify-content: center;
  gap: 20px;
  padding: 40px;

  .Card {
    width: 100%;
    //height: auto;
    height: 448px;
    padding: 40px;
    display: flex;
    flex-flow: column;
    gap: 32px;

    background: var(--Gradient, linear-gradient(270deg, #0C0B0B 0%, #2E2727 210.56%));
  }

  @include breakpoint($bk-phone) {
    flex-flow: column;
  }
}

.Block7 {
  width: 100%;
  height: 100%; // Временно
  display: flex;
  flex-flow: column;
  align-items: center;

  .WrapperCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 80px 20px;
    width: 100%;
    padding: 40px 20px;
    @include breakpoint($bk-phone) {
      display: flex;
      flex-flow: column;
      padding: 40px 45px;
      gap: 24px;
      margin: 0;
    }
  }

  .WrapperCardMobile {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .Card7 {
    display: grid;
    grid-template-rows: 0.5fr;
    border: 1px solid $primary-color;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .Content {
      max-width: 550px;
      height: 100%;
      padding: 24px;
      display: flex;
      flex-flow: column;
      gap: 16px;
    }

    h6 {
      text-align: left;
    }
  }
}

.Block8 {
  width: 100%;
  height: auto;
  padding: 40px;
  background: var(--Gradient, linear-gradient(270deg, #0C0B0B 0%, #2E2727 210.56%));
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 61px;
  @include breakpoint($bk-phone) {
    flex-flow: column-reverse;
    font-size: 16px;
  }

  .Title {
    max-width: 1000px;
    font-family: Jost, sans-serif;
    font-size: 32px;
    color: $text-01;
    @include breakpoint($bk-phone) {
      font-size: 16px;
    }
  }
}

.Highlight {
  color: $primary-color;
  font-weight: 600;
}

.Block9 {
  button {
    width: 100%;
  }

  width: 100%;
  height: 100%; // Временно
  display: flex;
  flex-flow: column;
  align-items: center;

  .WrapperCards {
    width: 100%;
    height: auto;
    display: flex;
    gap: 20px;
    padding: 24px;
    @include breakpoint($bk-phone) {
      flex-flow: column;
      gap: 40px;
    }

    .Card {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      flex-flow: column;
      padding: 45px 40px;
      margin-top: 196px;
      color: #E9E0E0;
      background: var(--Gradient, linear-gradient(270deg, #0C0B0B 0%, #2E2727 210.56%));

      .PriceButtons {
        display: flex;
        flex-flow: column;
        gap: 24px;
        margin-top: 64px;
      }

      .ImageWrapper {
        display: flex;
        flex-flow: column;
        gap: 32px;
        position: relative;
        top: -160px;

        align-items: center;
        width: 100%;
        height: 220px;

        .Title {
          font-size: 64px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 36px */
          letter-spacing: -2.16px;
          text-transform: uppercase;
          @include breakpoint($bk-phone) {
            font-size: 36px;
          }
        }

        img {
          height: 252px;
          width: 252px;
          object-fit: cover;
          border-radius: 999px;
        }
      }

      .Capture {
        color: $text-01;
        font-family: Jost, sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 20px */
        @include breakpoint($bk-phone) {
          font-size: 20px;
        }
      }

      ul {
        width: 100%;
        display: flex;
        flex-flow: column;
        gap: 12px;
        margin-left: 24px;
      }

      .Image {
        height: 203px;
        width: 203px;
        object-fit: cover;

        img {
          height: 203px;
          width: 203px;
        }
      }

      .Info {
        display: flex;
        flex-flow: column;
        gap: 40px;
        height: 100%;

        .CrossedOut {
          color: $text-02;
          text-decoration-line: line-through;
        }

        ul li {
          font-size: 24px;
          @include breakpoint($bk-phone) {
            font-size: 16px;
          }
        }
      }

      .PriceWrapper {
        font-family: Jost, sans-serif;
        display: flex;
        flex-flow: column;
        gap: 16px;

        .OldPrice {
          color: $text-02;
          text-align: center;

          /* Numeric 2 */
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 24px */
          text-decoration-line: line-through;
        }

        .Price {
          color: var(--Headline, #E9E0E0);
          text-align: center;

          /* Numeric 1 */
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 48px */
        }
      }

      .ButtonWrapper {
        width: 100%;
        display: flex;
        flex-flow: column;
        gap: 8px;
      }
    }
  }
}

.FAQ {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  .ff {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 24px;
    padding: 40px;
  }

  .WrapperCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 80px 20px;
    width: 100%;
    padding: 40px 20px;
    margin: 40px 0;
    @include breakpoint($bk-phone) {
      display: flex;
      flex-flow: column;
      padding: 40px 45px;
      gap: 24px;
      margin: 0;
    }
  }

  .Card7 {
    display: grid;
    grid-template-rows: auto auto;
    border: 1px solid $primary-color;


    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .Title {
      color: var(--text, #E1E1E1);
      font-family: Jost, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      text-align: center;
    }

    .Content {
      max-width: 550px;
      height: 100%;
      padding: 44px;
      display: flex;
      flex-flow: column;
      gap: 16px;
    }

    h6 {
      text-align: left;
    }
  }
}


.Footer {
  width: 100%;
  height: auto;

  a.button.ghost {
    color: $text-01;
    text-decoration: underline;
  }

  .Supprot {
    width: 100%;
    .Desk4 {
      color: #5F5E5E;;
      margin: 0;
    }

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 60px;
    background: var(--Gradient, linear-gradient(270deg, #0C0B0B 0%, #2E2727 210.56%));

    @include breakpoint($bk-phone) {
      flex-flow: column;
      gap: 24px;
      padding: 40px 24px;
    }
  }

  .Links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 40px;
    gap: 8px;
    @include breakpoint($bk-phone) {
      align-content: center;
      flex-flow: column;
      padding: 24px;
    }

    a {
      text-decoration: none;
      color: $text-02;
    }

    .Link {
      font-family: Jost, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
    }
  }
}

.Left {
  display: flex;
  flex-flow: column;
  width: auto;
  gap: 8px;

  @include breakpoint($bk-phone) {
    text-align: center;
    align-content: center;
    flex-flow: column;
    padding: 24px;
  }
}

.Right {
  display: flex;
  flex-flow: column;
  width: auto;
  gap: 8px;

  @include breakpoint($bk-phone) {
    text-align: center;
    align-content: center;
    flex-flow: column;
    padding: 24px;
  }
}

.Bold {
  font-weight: 800;
}



